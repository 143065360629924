import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';

import BuildIcon from '@mui/icons-material/Build';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.viemme2.it/">
	  	https://www.viemme2.it/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <BuildIcon sx={{ mr: 2 }} />
          <Typography variant="h7" color="inherit" noWrap>
            Attrezzature Viemme Due S.r.l.
          </Typography>
        </Toolbar>
      </AppBar>
    	<main>
        	<Container sx={{ py: 8 }} maxWidth="false">
          		<Grid container spacing={2}>
              		<Grid item xs={3}>
                		<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/6AL9501.png"
                    			alt="6AL9501"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
				  				<Typography gutterBottom variant="h7" component="div">
									Aspiragranuli PIOVAN con tramoggia COIBENTATA mod 6AL 9501
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									KW 1
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Macchina funzionante
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/ASPIRAGRANULI PIOVAN MOD 6AL9501.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
					</Grid>

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/6AL9499.png"
                    			alt="6AL9499"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  <Typography gutterBottom variant="h7" component="div">
									Aspiragranuli PIOVAN con tramoggia COIBENTATA mod 6AL 9499
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									KW 1 - Alto cm 150
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Macchina funzionante
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/ASPIRAGRANULI PIOVAN MOD 6AL9499.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/ag-coclea.png"
                    			alt="coclea"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  <Typography gutterBottom variant="h7" component="div">
									Nr 6 Aspiragranuli Coclea
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Potenza da KW 1,1 a KW 2,0
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Motori OK
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/ASPIRAGRANULI A COCLEA.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid> 
					
					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/ns-transtecnica.png"
                    			alt="6AL9501"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  <Typography gutterBottom variant="h7" component="div">
									Nastro TRANSTECNICA mod. T 12 7774
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Larghezza cm 135 TAPPETO cm 110
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Lunghezza cm 300
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Motoriduttore KW 0,50
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Impianto passo passo
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/NASTRI TRASPORTATORI ago 2021.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>  

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/ns-virginio.png"
                    			alt="virginio"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  <Typography gutterBottom variant="h7" component="div">
									Nastro VIRGINIO
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Larghezza cm 130 TAPPETO cm 97
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Lunghezza cm 700
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Motoriduttore KW 0,37
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Impianto passo passo
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/NASTRI TRASPORTATORI ago 2021.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/dosatori-materiale.png"
                    			alt="dosatori"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  <Typography gutterBottom variant="h7" component="div">
									Dosatori materiale
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/ALCUNI DOSATORI DI MATERIALE NOV 2021.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>  
					
					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/fi-rfec.png"
                    			alt="frigo"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  	<Typography gutterBottom variant="h7" component="div">
							  		Frigorifero INDUSTRIALFRIGO RFEC 8047
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/FRIGORIFERO  INDUSTRIALFRIGO RFEC 8047.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/golfari.png"
                    			alt="frigo"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  	<Typography gutterBottom variant="h7" component="div">
							  		Golfari - Staffe - Bulloni
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/GOLFARI STAFFE BULLONI giugno 2021.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>

					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/massello-pneumatico.png"
                    			alt="frigo"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  	<Typography gutterBottom variant="h7" component="div">
							  		Massello pneumatico - controllo sequenziale
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/MASSELLO OLEODINAMICO E PNEUMATICO.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>
					
					<Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/massello-oleodinamico.png"
                    			alt="frigo"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  	<Typography gutterBottom variant="h7" component="div">
							  		Massello oleodinamico x controllo sequenziale
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/MASSELLO OLEODINAMICO E PNEUMATICO.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>

					  <Grid item xs={3}>
						<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
                  			<CardMedia
                    			component="img"
                    			image="./static/imgs/centraline.png"
                    			alt="frigo"
                  			/>
                  			<CardContent sx={{ flexGrow: 1 }}>
							  	<Typography gutterBottom variant="h7" component="div">
							  		Centraline per camere calde - diversi modelli
								</Typography>
								<Typography align='left' variant="body2" color="text.secondary">
									Incatronic 5 zone - Plasthing 2 zone - Comat DME MF 12 zone - S.I. Engineering 4 zone - 
									CC Elettronica 3+3 zone - Scotti 8 zone - Gefran 4 zone - Asso 4 zone
								</Typography>
        	          		</CardContent>
            	    		<CardActions>
                    			<Button size="small" href="./static/pdf/att/centraline camera calda.pdf">Ingrandisci</Button>
                  			</CardActions>
                		</Card>
              		</Grid>	
          		</Grid>
        	</Container>
      	</main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}